.art-portfolio {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #000;
  font-family: 'Pixelated MS Sans Serif', Arial, sans-serif;
  color: #fff;
  overflow: hidden;
}

/* Override the default window-content overflow for the art portfolio window */
.window-content .art-portfolio {
  height: 100%;
}

/* Instead of using :has(), add a class to the parent window-content in the component */
.portfolio-window-content {
  overflow: hidden !important;
}

.portfolio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #000000;
  color: #fff;
}

.portfolio-header h2 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.portfolio-controls {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Search box styles */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #000000;
  padding: 2px;
  border: 1px solid #ffffff;
}

.search-input {
  border: none;
  background: #000000;
  padding: 2px 4px;
  font-family: 'Pixelated MS Sans Serif', Arial, sans-serif;
  font-size: 12px;
  width: 150px;
  outline: none;
  color: #ffffff;
}

.clear-search {
  padding: 0 4px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  background: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
}

/* View controls styles */
.view-controls {
  display: flex;
  gap: 2px;
}

.view-button, .random-button {
  padding: 0 6px;
  font-size: 16px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.random-button {
  margin-right: 48px;
  font-weight: bold;
}

.view-button.active {
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.no-results {
  padding: 20px;
  text-align: center;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin: 20px;
}

/* List view styles */
.image-slider.list-view {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #000000;
  color: #ffffff;
}

.image-slider.list-view .thumbnail-title {
  color: #ffffff;
  background-color: #000000;
}

.portfolio-menu .art-portfolio .image-slider.list-view .thumbnail-title {
  color: #ffffff;
  background-color: #000000;
}

.thumbnail-container.list-item {
  flex-direction: row;
  height: 60px;
  background-color: #000000;
  border: 1px solid #ffffff;
  align-items: center;
}

.list-item .image-thumbnail {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  background-color: #000000;
}

.list-item .thumbnail-title {
  text-align: left;
  padding: 5px 8px;
  color: #ffffff;
  background-color: #000000;
  width: auto;
  min-width: 100px;
  flex-grow: 1;
  margin-left: 5px;
}

.list-item-details {
  flex-shrink: 0;
  display: flex;
  gap: 10px;
  padding: 5px;
  font-size: 11px;
  color: #ffffff;
  background-color: #000000;
  margin-left: auto;
  align-items: center;
}

.list-detail {
  background-color: #000000;
  color: #ffffff;
  padding: 1px 4px;
  border-radius: 2px;
  border: 1px solid #ffffff;
}

/* Update scrollbar styles to be more streamlined */
.portfolio-content {
  flex: 1;
  padding: 8px;
  overflow-y: auto;
  background-color: #000000;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #000000;
}

/* Simplified scrollbar style */
.portfolio-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.portfolio-content::-webkit-scrollbar-track {
  background: #000000;
}

.portfolio-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
}

.portfolio-content::-webkit-scrollbar-button {
  display: none;
}

.art-category {
  margin-bottom: 12px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  background-color: #000000;
  cursor: pointer;
  user-select: none;
  height: 24px;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.category-header:hover {
  background-color: #000000;
  border-color: #ffffff;
}

.category-name {
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  background-color: #000000;
}

.toggle-indicator {
  font-family: monospace;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  background-color: #000000;
}

.category-images {
  margin-top: 4px;
  padding: 8px;
  background-color: #000000;
  border: 1px solid #ffffff;
}

.image-slider {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  height: 180px;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #000000;
  overflow: hidden;
  border: 1px solid #ffffff;
}

.thumbnail-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.image-thumbnail {
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  position: relative;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-title {
  padding: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  background-color: #000000;
  color: #ffffff;
}

.portfolio-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  background-color: #000000;
  color: #fff;
}

.art-count {
  font-weight: bold;
}

/* Loading indicator */
.image-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  color: #fff;
}

.loading-indicator {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Remove the general overflow rule that might be causing issues */
body, html, #root, .kos-window {
  overflow: hidden;
}

/* Add styles for when ArtPortfolio is embedded in the combined viewer */
.portfolio-menu .art-portfolio {
  padding: 5px;
  background-color: #000000;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.portfolio-menu .art-portfolio .portfolio-header {
  position: sticky;
  top: 0;
  background-color: #000000;
  color: #ffffff;
  z-index: 5;
  padding: 4px;
  font-size: 0.9em;
}

.portfolio-menu .art-portfolio .portfolio-header h2 {
  font-size: 12px;
}

.portfolio-menu .art-portfolio .portfolio-controls {
  gap: 2px;
}

.portfolio-menu .art-portfolio .view-button,
.portfolio-menu .art-portfolio .random-button {
  font-size: 14px;
  padding: 0 4px;
}

.portfolio-menu .art-portfolio .random-button {
  margin-right: 12px;
  font-size: 14px;
  padding: 0 4px;
}

.portfolio-menu .art-portfolio .search-container {
  max-width: 100px;
}

.portfolio-menu .art-portfolio .search-input {
  width: 80px;
  font-size: 11px;
}

.portfolio-menu .art-portfolio .portfolio-content {
  overflow-y: visible;
  padding: 0;
  flex: 1;
}

.portfolio-menu .art-portfolio .portfolio-footer {
  padding: 4px;
  font-size: 10px;
  position: sticky;
  bottom: 0;
  background-color: #000000;
  margin-right: 8px;
}

/* Apply the same scrollbar style to the embedded portfolio but with adjusted width */
.portfolio-menu .art-portfolio::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.portfolio-menu .art-portfolio::-webkit-scrollbar-track {
  background: #000000;
}

.portfolio-menu .art-portfolio::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
}

.portfolio-menu .art-portfolio::-webkit-scrollbar-button {
  display: none;
}

/* Ensure the category boxes fit properly in the smaller space */
.portfolio-menu .art-portfolio .art-category {
  margin-bottom: 8px;
}

.portfolio-menu .art-portfolio .category-header {
  padding: 4px 6px;
  height: 18px;
}

.portfolio-menu .art-portfolio .category-name {
  font-size: 12px;
}

.portfolio-menu .art-portfolio .toggle-indicator {
  font-size: 10px;
}

.portfolio-menu .art-portfolio .category-images {
  padding: 4px;
  margin-top: 2px;
}

/* Adjust image grid for tighter fit */
.portfolio-menu .art-portfolio .image-slider {
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 5px;
}

/* List view styles - ensure text is visible */
.list-item .thumbnail-title {
  width: 150px;
  text-align: left;
  padding: 5px 8px;
  color: #ffffff;
  background-color: #000000;
}

.list-item-details {
  flex: 1;
  display: flex;
  gap: 10px;
  padding: 5px 8px;
  font-size: 11px;
  color: #ffffff;
  background-color: #000000;
}

.list-detail {
  background-color: #000000;
  color: #ffffff;
  padding: 1px 4px;
  border-radius: 2px;
  border: 1px solid #ffffff;
}

/* Additional styles for embedded portfolio in combined viewer */
.portfolio-menu .art-portfolio .list-item .thumbnail-title {
  color: #ffffff;
  background-color: #000000;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portfolio-menu .art-portfolio .thumbnail-container.list-item {
  height: auto;
  min-height: 40px;
  max-height: 50px;
}

.portfolio-menu .art-portfolio .list-item .image-thumbnail {
  width: 35px;
  height: 35px;
  margin: 3px;
}

.portfolio-menu .art-portfolio .list-item .thumbnail-title {
  font-size: 11px;
  margin-left: 3px;
  padding: 2px 4px;
}

.portfolio-menu .art-portfolio .list-item-details {
  flex-wrap: wrap;
}

.portfolio-menu .art-portfolio .list-detail {
  margin-bottom: 2px;
}

/* Thumbnail adjustments for the portfolio in combined viewer */
.portfolio-menu .art-portfolio .thumbnail-container {
  height: 120px;
  margin-bottom: 5px;
}

.portfolio-menu .art-portfolio .image-thumbnail {
  height: 90px;
} 