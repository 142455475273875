/* Style for the high-res image viewer */
.art-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #000000;
  user-select: none;
  color: #ffffff;
}

.art-viewer.maximized {
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}

.art-viewer-header {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.art-viewer-title {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.art-viewer-controls {
  display: flex;
  gap: 8px;
}

.art-viewer-close {
  background-color: #000000;
  border: 1px solid #ffffff;
  padding: 2px 8px;
  cursor: pointer;
  font-size: 12px;
  color: #ffffff;
}

.art-viewer-close:active {
  border-style: inset;
}

.art-viewer-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
}

.art-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  background-color: #000000;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  will-change: transform;
  transform-origin: center center;
  border: none;
}

.image-container.zoomed {
  cursor: zoom-out;
}

.zoom-hint {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: #000000;
  color: #ffffff;
  padding: 4px 8px;
  border: 1px solid #ffffff;
  font-size: 12px;
  pointer-events: none;
  z-index: 1;
}

.art-metadata {
  padding: 16px;
  background: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.art-metadata h2 {
  margin: 0 0 8px 0;
  font-size: 18px;
}

.art-metadata p {
  margin: 4px 0;
  font-size: 14px;
}

.art-viewer-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  background: #000000;
  color: #ffffff;
  padding: 16px;
  border: 1px solid #ffffff;
}

.art-viewer-loading-indicator {
  width: 40px;
  height: 40px;
  border: 4px solid #000000;
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 8px;
}

.image-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  background: #000000;
}

.image-error-icon {
  font-size: 48px;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.art-viewer-info {
  padding: 12px;
  background-color: #000000;
  border-top: 1px solid #ffffff;
  color: #ffffff;
}

.art-info-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  color: #ffffff;
}

.art-info-table th {
  text-align: left;
  font-weight: bold;
  width: 25%;
  padding: 4px 8px;
  background-color: #000000;
  border: 1px solid #ffffff;
}

.art-info-table td {
  padding: 4px 8px;
  background-color: #000000;
  border: 1px solid #ffffff;
}

.loading-indicator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ffffff;
  background-color: #000000;
}

.loading-indicator {
  width: 30px;
  height: 30px;
  border: 3px solid #000000;
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
}

.error-placeholder {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.artwork-details {
  padding: 10px;
  background-color: #000000;
  color: #ffffff;
  overflow-y: auto;
  max-height: 200px;
  border-top: 1px solid #ffffff;
}

.artwork-details h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000;
  padding: 5px;
  border-bottom: 1px solid #ffffff;
}

.details-table {
  width: 100%;
  margin-bottom: 10px;
}

.details-table td {
  padding: 3px 5px;
  vertical-align: top;
}

.detail-label {
  font-weight: bold;
  width: 30%;
  background-color: #000000;
  color: #ffffff;
}

.detail-value {
  background-color: #000000;
  color: #ffffff;
}

.artwork-description {
  margin-top: 10px;
  line-height: 1.4;
}

.art-viewer-footer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  border-top: 1px solid #ffffff;
}

/* Custom scrollbars for artwork details */
.artwork-details::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.artwork-details::-webkit-scrollbar-track {
  background: #000000;
}

.artwork-details::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0;
}

.artwork-details::-webkit-scrollbar-button:single-button {
  background-color: #000000;
  display: block;
  border-style: solid;
  height: 16px;
  width: 16px;
}

/* Up arrow */
.artwork-details::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #ffffff transparent;
}

/* Down arrow */
.artwork-details::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
}

.art-viewer-content::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.art-viewer-content::-webkit-scrollbar-track {
  background: #000000;
}

.art-viewer-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.art-viewer-content::-webkit-scrollbar-button:single-button {
  background-color: #000000;
  display: block;
  border-style: solid;
  height: 16px;
  width: 16px;
}

/* Remove navigation-related styles */
.art-navigation-help,
.art-navigation-help p,
.art-navigation-help strong,
.art-navigation-controls,
.nav-button,
.nav-button:hover,
.nav-button:active {
  display: none;
}

.art-viewer-footer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  border-top: 1px solid #ffffff;
} 