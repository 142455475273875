/* 
 * Custom CSS to help disable autocomplete suggestions for certain elements 
 */

/* Target all p elements with data-no-autocomplete attribute */
[data-no-autocomplete="true"] {
  /* Add some visual indication that autocomplete is disabled (optional) */
  /* background-color: rgba(0, 0, 0, 0.01); */
  
  /* If your autocomplete uses a specific class for suggestion UI, target it here */
  /* For example, for VSCode's inline suggestions: */
  & .suggest-widget {
    display: none !important;
  }
  
  & .monaco-editor .ghost-text-decoration {
    display: none !important;
  }
  
  & .monaco-editor .suggest-widget {
    display: none !important;
  }
  
  /* Force any AI suggestion UI to be hidden */
  & *[class*="suggestion"],
  & *[class*="ghost"],
  & *[class*="inline-completion"],
  & *[class*="autocomplete"],
  & *[id*="suggestion"],
  & *[id*="ghost"],
  & *[id*="inline-completion"],
  & *[id*="autocomplete"] {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

/* Cursor specific styling - hide autocomplete UI */
.cursor-inline-suggestion,
.cursor-inline-ghost-text,
.cursor-ai-completion {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

/* Addition to React contentEditable elements */
[contenteditable="true"][data-no-autocomplete="true"] {
  /* Additional rules for contentEditable elements */
  caret-color: auto; /* Reset to normal cursor */
} 