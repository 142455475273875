.combined-art-viewer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
}

.portfolio-menu {
  width: 20%;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  background-color: #000000;
  padding-right: 2px; /* Add some right padding to prevent content from touching the edge */
}

.artwork-display {
  width: 80%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
}

/* Make sure ArtPortfolio and ArtViewer components fit their containers */
.portfolio-menu .art-portfolio {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #000000;
  color: #ffffff;
  box-sizing: border-box;
}

.artwork-display .art-viewer {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .combined-art-viewer {
    flex-direction: column;
  }
  
  .portfolio-menu {
    overflow: hidden;
    width: 100%;
    height: 35%;
    border-right: none;
    border-bottom: 1px solid #ffffff;
    padding-right: 0;
    padding-bottom: 2px;
  }
  
  .artwork-display {
    width: 100%;
    height: 65%;
  }
} 