/* Admin Panel Styles */
.admin-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Courier New', monospace;
  background-color: #000000;
}

.admin-header {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  color: white;
}

.admin-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.admin-message {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 14px;
  margin-left: 12px;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-message.success {
  background-color: #008000;
  color: white;
}

.admin-message.error {
  background-color: #cc0000;
  color: white;
}

.admin-message.info {
  background-color: #000000;
  color: white;
}

.admin-content {
  display: flex;
  height: calc(100% - 40px);
  overflow: hidden;
  position: relative;
}

/* Source Images Panel (Left) */
.source-images-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #000000;
}

.panel-header {
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  color: white;
}

.panel-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.refresh-button {
  font-size: 12px;
  padding: 2px 8px;
  cursor: pointer;
}

.refresh-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-categories {
  flex: 1;
  overflow-y: scroll;
  padding: 8px;
  max-height: calc(100% - 44px); /* Adjust based on header height */
  background-color: #000000;
}

/* Ensure scrollbars are always visible */
.image-categories::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  display: block;
}

.image-categories::-webkit-scrollbar-track {
  background-color: #000000;
  border: 1px solid #808080;
}

.image-categories::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #808080;
}

.image-categories::-webkit-scrollbar-button {
  display: block;
  height: 16px;
  width: 16px;
  background-color: #000000;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #808080;
}

.image-category {
  margin-bottom: 16px;
  transition: background-color 0.2s;
}

.image-category.drag-over {
  background-color: rgba(0, 102, 204, 0.1);
  border: 2px dashed #0066cc;
}

.image-category.drag-over .category-header {
  background-color: #0066cc;
  color: white;
}

.category-header {
  padding: 4px 8px;
  margin-bottom: 8px;
  background-color: #000000;
  font-size: 14px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin-bottom: 16px;
}

.image-thumbnail {
  position: relative;
  cursor: grab;
  padding: 4px;
  background-color: #000000;
  transition: background-color 0.2s, transform 0.1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
}

.image-thumbnail:active {
  cursor: grabbing;
  transform: scale(0.98);
}

.image-thumbnail.dragging {
  opacity: 0.5;
}

.image-thumbnail:hover {
  background-color: #d0d0d0;
}

.image-thumbnail.selected {
  background-color: #aaddff;
  border-color: #0066cc;
}

.image-thumbnail img {
  max-width: 100%;
  max-height: 90px;
  object-fit: contain;
}

.image-name {
  font-size: 11px;
  margin-top: 4px;
  text-align: center;
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
}

.no-images {
  padding: 16px;
  text-align: center;
  background-color: #f0f0f0;
}

.no-images button {
  margin-top: 16px;
  padding: 8px 16px;
}

.loading-images {
  padding: 20px;
  text-align: center;
  color: #666;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Image Preview Panel (Center) */
.image-preview-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 200px; /* Minimum width */
  flex: 1.2; /* Allow panel to grow slightly more than others */
}

.image-preview-container {
  flex: 1;
  overflow: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.image-preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  background-color: #333;
  margin-bottom: 10px;
  padding: 8px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-info {
  padding: 10px;
  background-color: #e0e0e0;
  font-size: 14px;
  line-height: 1.5;
}

.no-image-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  text-align: center;
}

/* Metadata Form Panel (Right) */
.metadata-form-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 200px; /* Minimum width */
  flex: 1; /* Allow panel to grow */
}

.metadata-form {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.form-field {
  margin-bottom: 16px;
}

.form-field input, 
.form-field textarea {
  width: 100%;
  padding: 8px;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #848484;
}

.form-field textarea {
  resize: vertical;
  min-height: 100px;
}

.form-field input:focus,
.form-field textarea:focus {
  outline: none;
  border-color: #0066cc;
  background-color: #000000;
  color: #ffffff;
}

.form-field label {
  display: block;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}

.form-preview {
  margin: 20px 0;
  font-size: 14px;
}

.filename-preview {
  margin-top: 8px;
  padding: 8px;
  background-color: #f0f0f0;
  font-family: 'Courier New', monospace;
  word-break: break-all;
}

.form-actions {
  display: flex;
  margin-top: 24px;
  gap: 10px;
}

.apply-button, .process-button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}

.apply-button {
  background-color: #000000;
}

.process-button {
  background-color: #000000;
}

.apply-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Resizer styles */
.panel-resizer {
  width: 8px;
  height: 100%;
  background-color: #000000;
  cursor: col-resize;
  position: relative;
  transition: background-color 0.2s;
  z-index: 10;
}

.panel-resizer:hover, 
.panel-resizer.resizing {
  background-color: #a0a0a0;
}

.panel-resizer::after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  left: 2px;
  width: 4px;
  height: 40px;
  background-color: #808080;
  border-radius: 2px;
}

/* Prevent selection while resizing */
.resizing-active {
  cursor: col-resize;
  user-select: none;
}

.resizing-active * {
  user-select: none;
  pointer-events: none;
}

/* Scrollbars - Windows 98 style */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #808080;
}

::-webkit-scrollbar-button {
  display: block;
  height: 16px;
  width: 16px;
  background-color: #000000;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #808080;
}

::-webkit-scrollbar-button:vertical:start:decrement {
  background-position: center 4px;
  background-repeat: no-repeat;
  background-size: 8px 4px;
}

::-webkit-scrollbar-button:vertical:end:increment {
  background-position: center 4px;
  background-repeat: no-repeat;
  background-size: 8px 4px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .admin-content {
    flex-direction: column;
    overflow-y: auto;
  }
  
  .source-images-panel,
  .image-preview-panel,
  .metadata-form-panel {
    width: 100%;
    height: auto;
    min-height: 300px;
    margin: 0 0 10px 0;
  }
  
  .panel-resizer {
    display: none;
  }
  
  .image-preview-container,
  .metadata-form {
    max-height: 500px;
  }
}

.metadata-panel input,
.metadata-panel textarea {
  width: 100%;
  margin-bottom: 8px;
  padding: 4px;
  background-color: #000;
  color: #fff;
  border: 1px solid #848484;
  font-family: 'MS Sans Serif', sans-serif;
}

.metadata-panel input:focus,
.metadata-panel textarea:focus {
  outline: none;
  border-color: #0066cc;
  background-color: #000;
  color: #fff;
}

.metadata-panel textarea {
  min-height: 100px;
  resize: vertical;
}

.metadata-panel label {
  display: block;
  margin-bottom: 4px;
  color: #fff;
} 