/* KoolSkull OS Desktop Styles - High Contrast Theme */
/* No font import needed - using system fonts */

:root {
  /* High Contrast Theme Colors */
  --desktop-background: #000000;
  --window-background: #000000;
  --window-text: #FFFFFF;
  --active-title-bar: #00FF00;
  --inactive-title-bar: #333333;
  --active-title-text: #000000;
  --inactive-title-text: #FFFFFF;
  --button-face: #000000;
  --button-text: #FFFFFF;
  --button-highlight: #FFFFFF;
  --button-shadow: #808080;
  --border-highlight: #FFFFFF;
  --border-shadow: #808080;
  --text-highlight-bg: #444444;
  --text-highlight-color: #FFFFFF;
  --hyperlink-color: #00FFFF;
  --visited-hyperlink: #FF00FF;
  --disabled-text: #808080;
  
  /* Font */
  --primary-font: 'Courier New', Courier, monospace;

  /* TheatreJS Styles */
  --theatre-base-hue: 120;
  --theatre-accent-hue: 120;
  --theatre-text-color: var(--button-text);
  --theatre-panel-bg: var(--button-face);
  --theatre-panel-border: var(--border-highlight);
}

/* Remove the text-shadow simulation for bold */

body {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font);
  background-color: var(--desktop-background);
  overflow: hidden;
  color: var(--window-text);
}

/* KoolSkull OS 3D Border Effect */
.kos-border {
  border-top: 2px solid var(--border-highlight);
  border-left: 2px solid var(--border-highlight);
  border-right: 2px solid var(--border-shadow);
  border-bottom: 2px solid var(--border-shadow);
  background-color: var(--button-face);
  color: var(--button-text);
}

.kos-border-inset {
  border-top: 2px solid var(--border-shadow);
  border-left: 2px solid var(--border-shadow);
  border-right: 2px solid var(--border-highlight);
  border-bottom: 2px solid var(--border-highlight);
  background-color: var(--window-background);
  color: var(--window-text);
}

/* Desktop */
.desktop {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: var(--desktop-background);
}

/* Background GIF with opacity control */
.desktop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('gif/screenglitch.gif');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  opacity: 0.15; /* Control only the GIF opacity */
  z-index: 0; /* Place behind other desktop elements */
  pointer-events: none; /* Allow clicking through to desktop elements */
}

/* Desktop Layout with Logo */
.desktop-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 0;
  height: calc(100% - 28px); /* Full height minus taskbar */
  overflow: auto;
  position: relative;
  z-index: 1; /* Above the background */
}

/* Desktop Logo Styles */
.desktop-logo {
  margin-bottom: 0;
  width: 555px;
  height: 220px;
  align-self: flex-start;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  padding: 20;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  image-rendering: pixelated; /* Nearest neighbor rendering */
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  display: block;
  opacity: 1; /* Slight transparency for aesthetic effect */
}

/* Desktop Icons - updated to work with the logo */
.desktop-icons {
  position: relative;
  z-index: 1; /* Place above the background GIF */
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  gap: 20px;
  width: 100%;
  margin-top: -0px; /* Adjusted from -30px to -25px to add 5px more space */
  padding-top: 15px; /* Increased from 10px to 15px to add more padding at top */
}

.desktop-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  width: 80px;
}

.desktop-icon.active {
  background-color: var(--text-highlight-bg);
}

.desktop-icon.active .icon-text {
  color: var(--text-highlight-color);
  text-shadow: none;
}

.icon-image {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  overflow: hidden;
  background-color: transparent;
  border: none;
}

.icon-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  background-color: transparent;
}

.icon-text {
  font-size: 12px;
  color: var(--window-text);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  font-family: var(--primary-font);
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px;
  border-radius: 1px;
}

/* Windows */
.window {
  position: absolute;
  background-color: var(--window-background);
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.3);
  z-index: 5; /* Above desktop icons and background */
}

/* Apply transition only for maximize/restore, not for dragging */
.window.maximizing {
  transition: width 0.15s, height 0.15s, top 0.15s, left 0.15s;
}

.window-header {
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
  padding: 3px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  cursor: move; /* Indicate draggable */
  user-select: none; /* Prevent text selection while dragging */
}

.window-header.active {
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
}

.window-header.inactive {
  background-color: var(--inactive-title-bar);
  color: var(--inactive-title-text);
}

.window-title {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  color: inherit; /* Inherit color from parent (window-header) */
  font-family: var(--primary-font);
}

.window-controls {
  display: flex;
  margin-left: 5px;
}

/* Window buttons in active window */
.window-header.active .window-button {
  background-color: var(--button-face);
  color: var(--button-text);
  border-color: var(--border-highlight);
}

/* Window buttons in inactive window */
.window-header.inactive .window-button {
  background-color: var(--button-face);
  color: var(--button-text);
  opacity: 0.8;
}

.window-button {
  width: 16px;
  height: 14px;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  line-height: 1;
  padding-bottom: 2px;
  background-color: var(--button-face);
  color: var(--button-text);
  font-family: var(--primary-font);
}

.window-button:hover {
  background-color: var(--text-highlight-bg);
  color: var(--text-highlight-color);
}

.window-button:active {
  border-top: 2px solid var(--border-shadow);
  border-left: 2px solid var(--border-shadow);
  border-right: 2px solid var(--border-highlight);
  border-bottom: 2px solid var(--border-highlight);
}

.window-content {
  padding: 10px;
  background-color: var(--window-background);
  height: calc(100% - 40px);
  overflow: auto;
  box-sizing: border-box;
  color: var(--window-text);
}

/* Taskbar */
.taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 28px;
  background-color: var(--button-face);
  display: flex;
  align-items: center;
  padding: 0 2px;
  z-index: 1000; /* Highest z-index to stay on top */
  justify-content: space-between; /* Space items evenly */
  overflow: visible; /* Allow overflow to be visible */
}

/* Start button stays on the left */
.start-button {
  flex-shrink: 0; /* Prevent start button from shrinking */
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin-right: 5px;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--button-face);
  color: var(--button-text);
  font-size: 22px;
  font-family: var(--primary-font);
  position: relative;
  z-index: 1002; /* Above crypto ticker */
}

.start-button.active {
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
}

.start-menu {
  position: fixed;
  bottom: 28px;
  left: 0;
  width: 200px;
  background-color: var(--button-face);
  border: 2px solid var(--border-highlight);
  z-index: 1003; /* Above start button and crypto ticker */
  color: var(--button-text);
  max-height: calc(100vh - 40px); /* Add max height */
  overflow-y: auto; /* Add scroll if needed */
}

.start-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 22px;
  font-family: var(--primary-font);
}

.start-menu-item:hover {
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
}

.start-menu-divider {
  height: 1px;
  background-color: var(--border-highlight);
  margin: 5px 0;
}

/* Taskbar buttons take priority and don't scroll */
.taskbar-buttons {
  display: flex;
  flex-grow: 1; /* Take all available space */
  overflow: visible; /* No scrolling */
  z-index: 1000;
  max-width: none; /* Remove max-width constraint */
  flex-wrap: nowrap; /* Don't wrap buttons */
}

/* Right side container for crypto and clock */
.taskbar-right {
  display: flex;
  align-items: center;
  flex-shrink: 1; /* Allow right side to shrink */
  margin-left: 5px; /* Spacing from buttons */
  z-index: 1001;
  min-width: 0; /* Allow container to shrink below content size */
  overflow: hidden; /* Hide overflow content */
}

/* Crypto Ticker now positioned within taskbar flow and can shrink */
.crypto-ticker {
  position: relative;
  bottom: auto;
  right: auto;
  background-color: var(--button-face);
  color: var(--button-text);
  font-size: 11px;
  padding: 2px 8px;
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  height: 20px;
  width: auto; /* Auto width */
  min-width: 0; /* Allow to shrink below content size */
  flex-shrink: 1; /* Allow shrinking */
  overflow: hidden;
  border-top: 1px solid var(--border-highlight);
  border-left: 1px solid var(--border-highlight);
  border-right: 1px solid var(--border-shadow);
  border-bottom: 1px solid var(--border-shadow);
  margin-right: 5px; /* Add margin between ticker and clock */
}

/* LA Clock doesn't shrink */
.la-clock {
  position: relative;
  bottom: auto;
  right: auto;
  background-color: var(--button-face);
  color: var(--button-text);
  font-size: 11px;
  padding: 2px 8px;
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  height: 20px;
  flex-shrink: 0; /* Don't shrink clock */
  border-top: 1px solid var(--border-highlight);
  border-left: 1px solid var(--border-highlight);
  border-right: 1px solid var(--border-shadow);
  border-bottom: 1px solid var(--border-shadow);
}

.clock-label {
  font-weight: bold;
  margin-right: 5px;
  color: var(--window-text);
}

.taskbar-button {
  padding: 3px 8px;
  margin-right: 3px;
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  min-width: 100px;
  flex-shrink: 0; /* Don't shrink buttons */
  display: flex;
  align-items: center;
  background-color: var(--button-face);
  color: var(--button-text);
  font-family: var(--primary-font);
}

.taskbar-button.active {
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
  font-weight: bold;
}

/* Form Elements */
.kos-button {
  padding: 4px 10px;
  cursor: pointer;
  font-size: 12px;
  background-color: var(--button-face);
  color: var(--button-text);
  font-family: var(--primary-font);
}

.kos-button:active {
  border-top: 2px solid var(--border-shadow);
  border-left: 2px solid var(--border-shadow);
  border-right: 2px solid var(--border-highlight);
  border-bottom: 2px solid var(--border-highlight);
}

.kos-input {
  border: 2px inset var(--button-face);
  background-color: var(--window-background);
  color: var(--window-text);
  padding: 3px 5px;
  font-size: 12px;
  font-family: var(--primary-font);
}

/* KoolSkull OS Fieldset */
.kos-fieldset {
  border: 2px solid;
  border-color: var(--border-highlight) var(--border-shadow) var(--border-shadow) var(--border-highlight);
  background-color: var(--window-background);
  padding: 12px;
  position: relative;
  margin-top: 20px;
}

.kos-fieldset-legend {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: var(--window-background);
  padding: 0 5px;
  font-weight: bold;
  font-size: 12px;
}

/* Tables */
.kos-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.kos-table th {
  background-color: var(--button-face);
  text-align: left;
  padding: 5px;
  border: 1px solid var(--border-highlight);
  color: var(--button-text);
}

.kos-table td {
  padding: 5px;
  border: 1px solid var(--border-highlight);
  background-color: var(--window-background);
  color: var(--window-text);
}

/* Links */
a, .kos-link {
  color: var(--hyperlink-color);
  text-decoration: underline;
}

a:visited, .kos-link:visited {
  color: var(--visited-hyperlink);
}

a:hover, .kos-link:hover {
  color: var(--active-title-text);
  background-color: var(--active-title-bar);
}

/* Window content headings */
.window-content h1, 
.window-content h2, 
.window-content h3, 
.window-content h4, 
.window-content h5, 
.window-content h6 {
  font-family: var(--primary-font);
}

/* Window content paragraphs */
.window-content p {
  font-family: var(--primary-font);
  font-size: 12px;
  line-height: 1.4;
}

/* Music Library Styles - Renamed to Discography */
.music-library, .discography {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: var(--primary-font);
}

.folder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
}

/* We're removing the folder-path but keeping the style for reference */
.folder-path {
  flex-grow: 1;
  padding: 3px 5px;
  margin-right: 10px;
  font-size: 12px;
  background-color: var(--window-background);
  color: var(--window-text);
}

.folder-actions {
  display: flex;
  gap: 5px;
}

.folder-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 5px;
  background-color: var(--window-background);
  margin-bottom: 5px;
}

.file-list {
  padding: 5px;
}

.file-list.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.file-list.list-view {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.file-list.list-view .file-item {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--border-shadow);
  padding-bottom: 5px;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 2px;
}

.file-item:hover {
  background-color: var(--text-highlight-bg);
  color: var(--text-highlight-color);
}

.file-item.selected {
  background-color: var(--text-highlight-bg);
  color: var(--text-highlight-color);
}

.file-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: var(--button-face);
  overflow: hidden;
  position: relative;
}

.album-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-icon-text {
  font-size: 24px;
  color: var(--button-text);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.file-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-name {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
}

.file-meta {
  font-size: 10px;
  color: var(--window-text);
  opacity: 0.8;
}

.folder-status-bar {
  padding: 3px 5px;
  font-size: 10px;
  background-color: var(--window-background);
  color: var(--window-text);
  display: flex;
  justify-content: space-between;
}

/* Style the emulator controls to match KoolSkull OS theme */
.ejs_menu_bar {
  background-color: var(--button-face) !important;
  border: 2px solid var(--border-highlight) !important;
}

.ejs_menu_button {
  color: var(--button-text) !important;
}

.ejs_menu_button:hover {
  background-color: var(--text-highlight-bg) !important;
  color: var(--text-highlight-color) !important;
}

/* Window resize handle */
.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  cursor: nwse-resize;
  background-image: linear-gradient(135deg, transparent 0%, transparent 50%, var(--border-highlight) 50%, var(--border-highlight) 100%);
  z-index: 100;
}

.window.resizing {
  user-select: none;
  transition: none;
}

/* Ensure the window content doesn't interfere with the resize handle */
.window-content {
  position: relative;
  height: calc(100% - 20px);
  overflow: auto;
  background-color: var(--window-background);
  padding: 4px;
}

/* JSPaint Styles */
.jspaint-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--window-background);
  display: flex;
  flex-direction: column;
}

.jspaint-container iframe {
  border: none;
  width: 100%;
  flex-grow: 1;
  background-color: #fff;
}

.jspaint-error {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: var(--window-background);
  color: var(--window-text);
}

.jspaint-error h3 {
  color: #FF0000;
  margin-bottom: 15px;
}

.jspaint-error p {
  margin-bottom: 15px;
  max-width: 400px;
}

.error-buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.paint-status-bar {
  height: 20px;
  background-color: var(--button-face);
  border-top: 1px solid var(--border-highlight);
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 11px;
  color: var(--button-text);
  font-family: var(--primary-font);
}

.status-item {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.status-label {
  font-weight: bold;
  margin-right: 5px;
}

/* Improved Simple Paint Styles */
.simple-paint {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--window-background);
}

.simple-paint-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.paint-toolbar {
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: var(--button-face);
  border-bottom: 1px solid var(--border-shadow);
  gap: 10px;
  flex-wrap: wrap;
}

.tool-group {
  display: flex;
  margin-right: 10px;
  gap: 3px;
}

.tool-group button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
}

.tool-group button.active {
  background-color: var(--text-highlight-bg);
  color: var(--text-highlight-color);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.color-picker {
  margin-right: 10px;
}

.color-picker input {
  width: 30px;
  height: 30px;
  padding: 0;
  border: 2px solid var(--border-highlight);
  cursor: pointer;
}

.line-width {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.line-width label {
  margin-right: 5px;
  font-size: 12px;
}

.line-width select {
  width: 60px;
  height: 24px;
  font-size: 12px;
}

.canvas-container {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  background-color: #DDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container canvas {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: crosshair;
}

/* Canvas Size Dialog Styles */
.size-dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.size-dialog {
  width: 300px;
  background-color: var(--button-face);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.size-dialog-content {
  padding: 15px;
  background-color: var(--window-background);
}

.size-input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.size-input-group label {
  width: 60px;
  font-size: 12px;
  margin-right: 5px;
}

.size-input-group input {
  width: 70px;
  margin-right: 5px;
}

.size-input-group span {
  font-size: 12px;
}

.size-checkbox-group {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.size-checkbox-group input {
  margin-right: 5px;
}

.size-checkbox-group label {
  font-size: 12px;
}

.size-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;
}

/* Preset size buttons */
.size-presets {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.presets-label {
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
}

.size-preset-button {
  font-size: 10px;
  padding: 2px 5px;
}

/* Status bar styles for both Simple Paint and JSPaint */
.paint-status-bar {
  height: 24px;
  background-color: var(--button-face);
  border-top: 1px solid var(--border-highlight);
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 11px;
  color: var(--button-text);
  font-family: var(--primary-font);
}

.status-item {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.status-label {
  font-weight: bold;
  margin-right: 5px;
}

/* Touch-friendly styles */
@media (pointer: coarse) {
  .tool-group button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  
  .color-picker input {
    width: 36px;
    height: 36px;
  }
  
  .line-width select {
    height: 36px;
    width: 70px;
    font-size: 14px;
  }
  
  .size-input-group input {
    height: 36px;
    width: 80px;
  }
  
  .size-preset-button {
    font-size: 12px;
    padding: 6px 10px;
  }
}

/* Crypto Ticker Styles */
.crypto-prices {
  display: flex;
  gap: 5px;
  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  justify-content: flex-end;
}

.crypto-prices::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.crypto-item {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-shrink: 1;
  margin-left: 2px;
  min-width: 0;
}

/* Make the first two coins (BTC, ETH) have priority in tight spaces */
.crypto-item:nth-child(1),
.crypto-item:nth-child(2) {
  flex-shrink: 0.5;
}

.crypto-symbol {
  font-weight: bold;
  font-size: 11px;
  white-space: nowrap;
}

.crypto-price {
  margin-right: 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crypto-change {
  font-size: 9px;
  min-width: 35px;
  text-align: right;
  white-space: nowrap;
}

/* TheatreJS Styles */
.theatre-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--window-background);
  color: var(--window-text);
  position: relative; /* Add position relative to contain absolute elements */
  overflow: hidden; /* Prevent overflow */
}

.theatre-toolbar {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: var(--button-face);
  border-bottom: 1px solid var(--border-shadow);
  justify-content: space-between;
}

.theatre-controls {
  display: flex;
  align-items: center;
}

.theatre-info {
  font-size: 12px;
  color: var(--button-text);
  margin-right: 10px;
}

.theatre-canvas-container {
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #000000;
}

.theatre-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: var(--window-text);
  z-index: 5;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--border-highlight);
  border-top: 4px solid var(--active-title-bar);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  font-size: 14px;
  color: var(--window-text);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.theatre-error {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.theatre-error h3 {
  color: #FF0000;
  margin-bottom: 15px;
}

.theatre-error p {
  margin-bottom: 15px;
  max-width: 400px;
}

.theatre-status-bar {
  height: 20px;
  background-color: var(--button-face);
  border-top: 1px solid var(--border-highlight);
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 11px;
  color: var(--button-text);
  font-family: var(--primary-font);
  justify-content: space-between;
}

/* Make Theatre.js UI elements match KoolSkull OS style */
div[data-theatric] {
  font-family: var(--primary-font) !important;
  position: absolute !important;
  max-width: 100% !important;
  max-height: calc(100% - 40px) !important; /* Leave space for toolbar and status bar */
  overflow: auto !important;
  z-index: 10 !important; /* Keep below window controls but above canvas */
}

/* Ensure Theatre.js UI is contained within the window */
.theatre-container div[data-theatric],
.theatre-ui-contained {
  top: 30px !important; /* Position below toolbar */
  left: 0 !important;
  right: 0 !important;
  bottom: 20px !important; /* Position above status bar */
  position: absolute !important;
  max-width: 100% !important;
  max-height: calc(100% - 50px) !important;
  overflow: auto !important;
  z-index: 10 !important; /* Keep below window controls but above canvas */
}

/* Style Theatre.js buttons */
div[data-theatric] button {
  background-color: var(--button-face) !important;
  color: var(--button-text) !important;
  border-top: 2px solid var(--border-highlight) !important;
  border-left: 2px solid var(--border-highlight) !important;
  border-right: 2px solid var(--border-shadow) !important;
  border-bottom: 2px solid var(--border-shadow) !important;
  border-radius: 0 !important;
}

/* Style Theatre.js panels */
div[data-theatric] div[role="dialog"] {
  background-color: var(--button-face) !important;
  border-top: 2px solid var(--border-highlight) !important;
  border-left: 2px solid var(--border-highlight) !important;
  border-right: 2px solid var(--border-shadow) !important;
  border-bottom: 2px solid var(--border-shadow) !important;
  border-radius: 0 !important;
  max-width: 90% !important; /* Prevent panels from extending beyond window */
  max-height: 80% !important;
  overflow: auto !important;
}

/* Sequence Editor Styles */
.sequence-editor {
  background-color: #000000;
  border: 2px solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  padding: 10px;
  margin-top: 5px;
  font-family: 'MS Sans Serif', Arial, sans-serif;
  font-size: 11px;
  overflow: auto;
  max-height: 300px;
}

.sequence-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #808080;
  padding-bottom: 5px;
}

.sequence-header h4 {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.sequence-controls {
  display: flex;
  align-items: center;
}

.frame-counter {
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px inset #808080;
  padding: 2px 5px;
  font-family: 'Courier New', monospace;
}

.sequence-timeline {
  position: relative;
  margin: 10px 0;
  padding: 5px 0;
}

.timeline-track {
  position: relative;
  height: 20px;
  background-color: #ffffff;
  border: 1px inset #808080;
  margin-bottom: 5px;
}

.timeline-marker {
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #808080;
  bottom: 0;
}

.marker-label {
  position: absolute;
  font-size: 9px;
  bottom: -15px;
  left: -5px;
  color: #000000;
}

.keyframe-marker {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border: 1px solid #800000;
  border-radius: 50%;
  top: 5px;
  transform: translateX(-4px);
  cursor: pointer;
}

.keyframe-marker:hover {
  background-color: #ff6666;
}

.playhead {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #0000ff;
  top: 0;
  transform: translateX(-1px);
}

.timeline-scrubber {
  width: 100%;
  margin: 5px 0;
  height: 15px;
}

.sequence-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.property-group {
  flex: 1;
  min-width: 200px;
  background-color: #d4d0c8;
  border: 1px solid #808080;
  padding: 5px;
}

.property-group h5 {
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: bold;
  border-bottom: 1px solid #808080;
  padding-bottom: 3px;
}

.property-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.property-row label {
  width: 80px;
  font-size: 10px;
}

.property-row input[type="range"] {
  flex: 1;
  height: 15px;
}

.property-value {
  width: 40px;
  text-align: right;
  font-family: 'Courier New', monospace;
  font-size: 10px;
  margin-left: 5px;
  background-color: #ffffff;
  border: 1px inset #808080;
  padding: 1px 3px;
}

/* Customize range input to look more like KoolSkull OS */
input[type="range"] {
  -webkit-appearance: none;
  background-color: #d4d0c8;
  border: 1px inset #808080;
  height: 15px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #000000;
  border: 1px outset #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background-color: #000000;
  border: 1px outset #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
}

/* Ensure the container layout works with the sequence editor */
.theatre-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Make sure the canvas fills the container */
.theatre-canvas-container canvas {
  width: 100%;
  height: 100%;
  display: block;
}

/* Album Art Viewer */
.album-art-viewer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 450px;
  background-color: var(--window-background);
  border: 2px solid;
  border-color: var(--border-highlight) var(--border-shadow) var(--border-shadow) var(--border-highlight);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.album-art-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 28px; /* Leave space for taskbar */
  width: 100%;
  height: calc(100% - 28px);
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  border: none;
}

.album-art-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--active-title-bar);
  color: var(--active-title-text);
  padding: 3px 8px;
  user-select: none;
  border-bottom: 1px solid var(--border-shadow);
}

.album-art-fullscreen .album-art-header {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.album-art-title {
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.album-art-controls {
  display: flex;
  gap: 5px;
}

.close-button {
  font-size: 11px;
  min-width: auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.album-art-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.album-art-fullscreen .album-art-container {
  flex-direction: column;
  padding: 40px;
}

.album-art-image {
  max-width: 100%;
  max-height: 75%;
  object-fit: contain;
  border: 1px solid var(--border-shadow);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.album-art-fullscreen .album-art-image {
  max-height: 90%;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

.album-info {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.album-art-fullscreen .album-info {
  color: white;
  margin-top: 20px;
}

.album-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.album-artist {
  font-size: 12px;
  margin-bottom: 3px;
}

.album-year, .album-tracks {
  font-size: 11px;
  color: var(--button-shadow);
}

.album-art-fullscreen .album-year,
.album-art-fullscreen .album-tracks {
  color: rgba(255, 255, 255, 0.7);
}

/* Highlight selected album in discography */
.file-item.selected {
  background-color: var(--highlight-background);
  color: var(--highlight-text);
}

.file-item.selected .file-meta {
  color: var(--highlight-text);
}

.album-art-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--window-background);
  position: relative;
  overflow: hidden;
}

.album-art-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.album-art-container.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button-shadow);
  font-style: italic;
}

.album-art-image {
  max-width: 100%;
  max-height: 75%;
  object-fit: contain;
  border: 1px solid var(--border-shadow);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.album-info {
  margin-top: 5px;
  width: 100%;
}

.album-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.album-artist {
  font-size: 12px;
  margin-bottom: 3px;
}

.album-year, .album-tracks {
  font-size: 11px;
  color: var(--button-shadow);
}

/* Media queries for responsive layout */
@media (max-height: 500px) {
  .album-art-image {
    max-height: 60%;
  }
  
  .album-info {
    margin-top: 2px;
  }
  
  .album-title,
  .album-artist,
  .album-year,
  .album-tracks {
    margin-bottom: 2px;
  }
}

/* Square album view for discography */
.file-list.square-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
  padding: 10px;
}

.square-view .file-item {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  aspect-ratio: 1/1; /* Make items square */
  overflow: hidden;
}

.square-album {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.square-album-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.square-album {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.square-album-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 8px 5px;
  transform: translateY(101%); /* Start just off-screen */
  transition: transform 0.2s ease;
  text-align: center;
  z-index: 3;
}

.square-album-title {
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.square-album-tracks {
  font-size: 10px;
  opacity: 0.8;
}

.square-view .file-item:hover .square-album-info {
  transform: translateY(0);
}

.square-album-fallback {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  background-color: var(--button-face);
  color: var(--button-text);
}

.square-view .file-item.selected {
  outline: 3px solid var(--active-title-bar);
  outline-offset: -3px;
  z-index: 2;
}

/* Adjust existing grid and list views for consistency */
.file-list.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.file-list.list-view {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.album-art-content.maximized {
  background-color: black;
  padding: 0;
}

.album-art-content.maximized .album-art-container {
  padding: 0;
}

.album-art-content.maximized .album-art-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0;
  border: none;
  box-shadow: none;
}

/* Art Info Toggle Button */
.taskbar-button.kos-button {
  font-size: 12px;
  padding: 2px 6px;
  margin: 2px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 20px;
  white-space: nowrap;
}

.taskbar-button.kos-button:hover {
  background-color: var(--kos-button-hover);
}

.taskbar-button.kos-button:active {
  background-color: var(--kos-button-active);
  transform: translateY(1px);
} 